<template>
    <div class="menu">
        <div class="p-4 d-flex justify-content-between noselect">
            <router-link
                v-for="item in items"
                :key="item.key"
                :to="item.link"
                class="link d-flex flex-column align-items-center"
                :class="{ active: item.key === activeNav }"
            >
                <SvgIcon class="icon pb-2" :icon="item.icon" :hasFill="false" />
                <div>{{ item.name }}</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { Vue } from "vue-property-decorator";
import SvgIcon from "@/components/SvgIcon.vue";

export default Vue.extend({
    components: {
        SvgIcon,
    },
    data() {
        return {
            items: [
                {
                    key: 1,
                    name: "My queue",
                    link: "/documents",
                    icon: "my-queue",
                },
                { key: 2, name: "Account", link: "/profile", icon: "user" },
            ],
        };
    },
    props: {
        activeNav: {
            type: Number,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
@import "../styles/scss/utils";
.menu {
    font-size: 1rem;
    height: $footer-heigth;
    background-color: #fff;
    position: fixed;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    .icon {
        font-size: 2.4rem;
    }
    .link {
        text-decoration: none;
    }
    .active {
        font-weight: 600;
        font-style: normal;
    }
}
</style>
